import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconNUUM: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            viewBox="0 0 164 84"
        >
            <path
                fill="#552BEF"
                d="M153.806 26.241c.144-2.015 0-2.516-.788-2.516-.43 0-.787.431-1.362 2.084l-8.898 24.533c-1.149 3.092-2.441 4.532-4.595 4.462-2.224-.07-3.734-1.727-4.234-5.182l-3.085-21.58c-.287-1.797-.501-2.372-1.075-2.372-.644 0-.788.431-1.149 2.372l-3.59 20.717-6.818-1.295 7.68-38.058c1.436-6.978 3.015-9.137 6.531-9.137 3.016 0 4.952 2.591 5.526 8.2l2.872 26.761c.07.288.214.358.431.358s.357-.144.431-.288l8.254-22.587c2.441-6.617 4.952-9.281 8.181-8.994 3.733.358 4.882 2.879 4.882 10.289v44.747l-11.627 2.23 2.441-34.748-.008.004ZM17.722 38.183c.07-1.797.5-2.66 1.075-2.66.644 0 1.005.5 1.436 2.59l4.882 22.875c1.793 8.561 4.164 12.23 7.824 12.23 3.877 0 6.318-3.525 8.972-12.88l16.148-56.4c.214-.864.431-1.654.431-2.23 0-1.295-.788-2.014-3.016-1.582l-14.21 2.59-3.517 49.925c-.144 1.797-.5 2.66-1.149 2.66-.718 0-1.075-.72-1.362-2.59l-5.6-39.637c-1.005-6.978-3.085-10.358-6.531-10.358-3.877 0-6.1 3.022-7.68 10.72L1 83l14.355-2.734 2.367-42.083Zm41.125-.576c0 11.941 4.521 18.418 12.345 18.418 8.755 0 14.93-8.487 14.93-29.566 0-4.963-.07-10.646-.358-15.177-1.867 1.94-4.808 4.531-7.106 6.044.288 2.66.431 7.048.431 12.373 0 9.351-.931 19.71-4.377 19.71-2.01 0-2.655-4.676-2.655-10.79 0-7.624 1.005-16.691 1.65-19.282-2.224.72-9.116 1.871-14.138 2.159-.43 4.244-.718 10.79-.718 16.115l-.004-.004Zm43.639 11.727c7.681 0 11.914-7.191 11.914-19.783 0-4.675-.143-7.623-.213-9.137-1.292-.645-3.877-1.87-6.388-3.598.07 1.941.287 6.189.287 12.086 0 9.35-1.218 14.602-3.159 14.602-2.08 0-2.871-3.454-2.871-14.244 0-7.624.357-14.533.43-16.404-4.164-2.948-9.76-7.553-11.052-8.993-.718 11.798-.862 18.92-.862 25.396 0 12.086 4.739 20.071 11.914 20.071v.005Z"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconNUUM
