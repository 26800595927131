import React from 'react';
import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    Container,
    Divider,
    Grid,
    IconButton,
    Stack,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import {deepPurple} from "@mui/material/colors";
import BadgeH1 from "../util/BadgeH1";
import SvgIconDTF from "../util/SvgIconDTF";
import SvgIconGooglePlay from "../util/SvgIconGooglePlay";
import SvgIconHuaweiAppGallery from "../util/SvgIconHuaweiAppGallery";
import SvgIconIsaevSpace from "../util/SvgIconIsaevSpace";
import SvgIconIXBT from "../util/SvgIconIXBT";
import SvgIconPikabu from "../util/SvgIconPikabu";
import SvgIconRuStore from "../util/SvgIconRuStore";
import SvgIconStopGame from "../util/SvgIconStopGame";
import SvgIconTelegram from "../util/SvgIconTelegram";
import SvgIconTikTok from "../util/SvgIconTikTok";
import SvgIconVK from "../util/SvgIconVK";
import SvgIconVKPlay from "../util/SvgIconVKPlay";
import SvgIconXiaomiGetApps from "../util/SvgIconXiaomiGetApps";
import SvgIconYouTube from "../util/SvgIconYouTube";
import SvgIconInstagram from "../util/SvgIconInstagram";
import SvgIconBandLink from "../util/SvgIconBandLink";
import SvgIconMailYandex from "../util/SvgIconMailYandex";
import SvgIconMailGoogle from "../util/SvgIconMailGoogle";
import SvgIconZip from "../util/SvgIconZip";
import SvgIconReddit from "../util/SvgIconReddit";
import SvgIconNUUM from "../util/SvgIconNUUM";
import SvgIconYandexTips from "../util/SvgIconYandexTips";
import SvgIconBoosty from "../util/SvgIconBoosty";
import SvgIconSponsr from "../util/SvgIconSponsr";
import SvgIconDonationAlerts from "../util/SvgIconDonationAlerts";
import SvgIconMemeAlerts from "../util/SvgIconMemeAlerts";
import SvgIconBoomstarter from "../util/SvgIconBoomstarter";
import SvgIconSFEROOM from "../util/SvgIconSFEROOM";
import SvgIconVKMusic from "../util/SvgIconVKMusic";
import SvgIconYandexMusic from "../util/SvgIconYandexMusic";
import SvgIconZvuk from "../util/SvgIconZvuk";
import SvgIconMTSMusic from "../util/SvgIconMTSMusic";
import SvgIconAppleMusic from "../util/SvgIconAppleMusic";
import SvgIconSpotify from "../util/SvgIconSpotify";
import SvgIconTaplink from "../util/SvgIconTaplink";
import SvgIconX from "../util/SvgIconX";
import SvgIconTumblr from "../util/SvgIconTumblr";
import SvgIconDzen from "../util/SvgIconDzen";
import SvgIconTheDailyTelefrag from "../util/SvgIconTheDailyTelefrag";
import SvgIconRUTUBE from "../util/SvgIconRUTUBE";
import SvgIconSteam from "../util/SvgIconSteam";

function App() {
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton href="/" sx={{p: 0, mr: 2}}>
                        <Avatar sx={{bgcolor: deepPurple[500]}}>
                            <SvgIconIsaevSpace sx={{color: 'white'}}/>
                        </Avatar>
                    </IconButton>
                    <Box flexGrow={1}>
                        <Badge badgeContent="space" sx={{color: deepPurple[200]}}>
                            <Typography variant="h6" color="#fff">ISAEV</Typography>
                        </Badge>
                    </Box>
                    <IconButton
                        href="https://t.me/isaev_inc"
                        target="_blank"
                        color="primary"
                    >
                        <SvgIconTelegram/>
                    </IconButton>
                    <IconButton
                        href="https://vk.com/isaev_inc"
                        target="_blank"
                        color="info"
                    >
                        <SvgIconVK/>
                    </IconButton>
                    <IconButton
                        href="https://youtube.com/@isaev_inc"
                        target="_blank"
                        color="error"
                    >
                        <SvgIconYouTube/>
                    </IconButton>
                    <IconButton
                        href="https://tiktok.com/@isaev_inc"
                        target="_blank"
                    >
                        <SvgIconTikTok/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                height={480}
                sx={{
                    backgroundImage: 'url(bg.jpg)',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            >
                <BadgeH1 badgeContent="space" sx={{color: deepPurple[200]}}>
                    <Typography variant="h1" color="#fff">ISAEV</Typography>
                </BadgeH1>
                <Typography variant="subtitle1" align="center" maxWidth={600}>
                    Мы очень сильно любим видеоигры, рассказываем о наших проектах, делимся своим опытом и бэкстейджем
                    разработки.
                </Typography>
            </Stack>
            <Container sx={{pt: 4}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardActionArea
                                href="https://vk.com/video-218216146_456239067"
                                target="_blank"
                            >
                                <CardMedia
                                    component="img"
                                    image="vittalucis.jpg"
                                    alt="Vitta Lucis"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h2" mb={4}>Vitta Lucis</Typography>
                        <Typography mb={2}>
                            Отчасти вера в бога оказалась ошибкой - проклятья, демоны и прочая нечисть существуют.
                        </Typography>
                        <Typography mb={4}>
                            Игра находится в разработке. Присоединяйся к нашим социальным сетям, чтобы следить за
                            новостями!
                        </Typography>
                        <Typography>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SvgIconBandLink/>}
                                href="https://band.link/vittalucismaintheme"
                                target="_blank"
                                sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                            >
                                Саундтрек
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SvgIconZip/>}
                                href="ISAEV SPACE - Vitta Lucis - Press-kit.zip"
                                target="_blank"
                                sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                            >
                                Пресс-кит
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{my: 4}}/>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardActionArea
                                href="https://vk.com/video-218216146_456239058"
                                target="_blank"
                            >
                                <CardMedia
                                    component="img"
                                    image="ikenorris.jpg"
                                    alt="Vitta Lucis"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h2" mb={4}>Ike Norris</Typography>
                        <Typography mb={4}>
                            Кто же это такой? Хороший мальчик и очень дружелюбный пёс, а также член нашей семьи! Как не
                            сложно догадаться, сюжет игры будет связан именно с ним. Всех карт раскрывать не будем, наша
                            игра об этом расскажет!
                        </Typography>
                        <Typography>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SvgIconVKPlay/>}
                                href="https://vkplay.ru/play/game/ike_norris"
                                target="_blank"
                                sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                            >
                                VK Play
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SvgIconRuStore/>}
                                href="https://apps.rustore.ru/app/com.isaev.ikenorris"
                                target="_blank"
                                sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                            >
                                RuStore
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SvgIconGooglePlay/>}
                                href="https://play.google.com/store/apps/details?id=com.isaev.ikenorris"
                                target="_blank"
                                sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                            >
                                Google Play
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SvgIconXiaomiGetApps/>}
                                href="https://global.app.mi.com/details?id=com.isaev.ikenorris"
                                target="_blank"
                                sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                            >
                                Xiaomi GetApps
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SvgIconHuaweiAppGallery/>}
                                href="https://appgallery.huawei.com/app/C108095783"
                                target="_blank"
                                sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                            >
                                Huawei AppGallery
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SvgIconBandLink/>}
                                href="https://band.link/isaevspaceikenorris"
                                target="_blank"
                                sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                            >
                                Саундтрек
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SvgIconZip/>}
                                href="ISAEV SPACE - Ike Norris - Press-kit.zip"
                                target="_blank"
                                sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                            >
                                Пресс-кит
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{my: 4}}/>
                <Typography variant="h2" align="center">Соцсети</Typography>
                <Typography align="center" mb={4}>Все свежие новости</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://t.me/isaev_inc"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconTelegram/>
                                        </Avatar>
                                    }
                                    title="Telegram"
                                    subheader="@isaev_inc"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://vk.com/isaev_inc"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconVK/>
                                        </Avatar>
                                    }
                                    title="VK"
                                    subheader="@isaev_inc"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://youtube.com/@isaev_inc"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconYouTube/>
                                        </Avatar>
                                    }
                                    title="YouTube"
                                    subheader="@isaev_inc"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://tiktok.com/@isaev_inc"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconTikTok sx={{color: useTheme().palette.text.primary}}/>
                                        </Avatar>
                                    }
                                    title="TikTok"
                                    subheader="@isaev_inc"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://dtf.ru/u/1440702-isaev-space"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconDTF sx={{color: useTheme().palette.text.primary}}/>
                                        </Avatar>
                                    }
                                    title="DTF"
                                    subheader="u/1440702-isaev-space"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://pikabu.ru/@NanakiLucis"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.success.main}}>
                                            <SvgIconPikabu/>
                                        </Avatar>
                                    }
                                    title="Пикабу"
                                    subheader="@NanakiLucis"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://www.reddit.com/user/isaev_space"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconReddit/>
                                        </Avatar>
                                    }
                                    title="Reddit"
                                    subheader="u/isaev_space"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://x.com/ISAEV_SPACE"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconX sx={{color: useTheme().palette.text.primary}}/>
                                        </Avatar>
                                    }
                                    title="X"
                                    subheader="@ISAEV_SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
                <Divider sx={{my: 4}}/>
                <Typography variant="h2" align="center">О нас</Typography>
                <Typography align="center" mb={4}>Наши контакты и соцсети</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Card sx={{display: 'flex', flexDirection: 'column', height: 1}}>
                            <CardHeader
                                avatar={<Avatar src="nanaki.gif" alt="Nanaki"/>}
                                title="Nanaki"
                                subheader="Сергей Исаев"
                            />
                            <CardContent sx={{flexGrow: 1}}>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Всё" variant="outlined" size="small" color="secondary"/>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton
                                    href="https://t.me/nanaki_lucis"
                                    target="_blank"
                                    color="primary"
                                >
                                    <SvgIconTelegram/>
                                </IconButton>
                                <IconButton
                                    href="https://vk.com/nanaki"
                                    target="_blank"
                                    color="info"
                                >
                                    <SvgIconVK/>
                                </IconButton>
                                <IconButton
                                    href="https://youtube.com/@nanaki_x"
                                    target="_blank"
                                    color="error"
                                >
                                    <SvgIconYouTube/>
                                </IconButton>
                                <IconButton
                                    href="https://tiktok.com/@nanaki_x"
                                    target="_blank"
                                >
                                    <SvgIconTikTok/>
                                </IconButton>
                                <IconButton
                                    href="https://instagram.com/__nanaki__"
                                    target="_blank"
                                >
                                    <SvgIconInstagram/>
                                </IconButton>
                                <IconButton
                                    href="mailto:nanaki.god@gmail.com"
                                    target="_blank"
                                >
                                    <SvgIconMailGoogle/>
                                </IconButton>
                                <IconButton
                                    href="mailto:nanaki.god@yandex.ru"
                                    target="_blank"
                                >
                                    <SvgIconMailYandex/>
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{display: 'flex', flexDirection: 'column', height: 1}}>
                            <CardHeader
                                avatar={<Avatar src="elguraya.gif" alt="Elguraya"/>}
                                title="Elguraya"
                                subheader="Маргарита Исаева"
                            />
                            <CardContent sx={{flexGrow: 1}}>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Рисование" variant="outlined" size="small" color="error"/>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Озвучивание" variant="outlined" size="small" color="success"/>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Тестирование" variant="outlined" size="small" color="info"/>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton
                                    href="https://t.me/elguraya"
                                    target="_blank"
                                    color="primary"
                                >
                                    <SvgIconTelegram/>
                                </IconButton>
                                <IconButton
                                    href="https://vk.com/elguraya"
                                    target="_blank"
                                    color="info"
                                >
                                    <SvgIconVK/>
                                </IconButton>
                                <IconButton
                                    href="https://youtube.com/@elguraya"
                                    target="_blank"
                                    color="error"
                                >
                                    <SvgIconYouTube/>
                                </IconButton>
                                <IconButton
                                    href="https://tiktok.com/@elguraya"
                                    target="_blank"
                                >
                                    <SvgIconTikTok/>
                                </IconButton>
                                <IconButton
                                    href="https://instagram.com/elguraya"
                                    target="_blank"
                                >
                                    <SvgIconInstagram/>
                                </IconButton>
                                <IconButton
                                    href="mailto:elguraya@gmail.com"
                                    target="_blank"
                                >
                                    <SvgIconMailGoogle/>
                                </IconButton>
                                <IconButton
                                    href="mailto:elguraya@yandex.ru"
                                    target="_blank"
                                >
                                    <SvgIconMailYandex/>
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Card sx={{display: 'flex', flexDirection: 'column', height: 1}}>
                            <CardHeader
                                avatar={<Avatar src="TonyAbadeer.gif" alt="TonyAbadeer"/>}
                                title="TonyAbadeer"
                                subheader="Антон Малофеев"
                            />
                            <CardContent sx={{flexGrow: 1}}>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Моделирование" variant="outlined" size="small" color="warning"/>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Озвучивание" variant="outlined" size="small" color="success"/>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Тестирование" variant="outlined" size="small" color="info"/>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton
                                    href="https://t.me/TonyAbadeer"
                                    target="_blank"
                                    color="primary"
                                >
                                    <SvgIconTelegram/>
                                </IconButton>
                                <IconButton
                                    href="mailto:bigsin90@gmail.com"
                                    target="_blank"
                                >
                                    <SvgIconMailGoogle/>
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Card sx={{display: 'flex', flexDirection: 'column', height: 1}}>
                            <CardHeader
                                avatar={<Avatar src="kowarina.gif" alt="kowarina"/>}
                                title="kowarina"
                                subheader="Арина Ковешникова"
                            />
                            <CardContent sx={{flexGrow: 1}}>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Рисование" variant="outlined" size="small" color="error"/>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Озвучивание" variant="outlined" size="small" color="success"/>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Тестирование" variant="outlined" size="small" color="info"/>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton
                                    href="https://t.me/arikosika"
                                    target="_blank"
                                    color="primary"
                                >
                                    <SvgIconTelegram/>
                                </IconButton>
                                <IconButton
                                    href="https://vk.com/strawberry_fish"
                                    target="_blank"
                                    color="info"
                                >
                                    <SvgIconVK/>
                                </IconButton>
                                <IconButton
                                    href="https://youtube.com/@kowarina"
                                    target="_blank"
                                    color="error"
                                >
                                    <SvgIconYouTube/>
                                </IconButton>
                                <IconButton
                                    href="https://tiktok.com/@kowarina2.0"
                                    target="_blank"
                                >
                                    <SvgIconTikTok/>
                                </IconButton>
                                <IconButton
                                    href="https://instagram.com/kowarina"
                                    target="_blank"
                                >
                                    <SvgIconInstagram/>
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Card sx={{display: 'flex', flexDirection: 'column', height: 1}}>
                            <CardHeader
                                avatar={<Avatar src="prom1k.gif" alt="prom1k"/>}
                                title="prom1k"
                                subheader="Дмитрий Сверчков"
                            />
                            <CardContent sx={{flexGrow: 1}}>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Озвучивание" variant="outlined" size="small" color="success"/>
                                <Chip sx={{mr: 0.5, mb: 0.5}} label="Тестирование" variant="outlined" size="small" color="info"/>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton
                                    href="https://t.me/prom1k"
                                    target="_blank"
                                    color="primary"
                                >
                                    <SvgIconTelegram/>
                                </IconButton>
                                <IconButton
                                    href="https://vk.com/id24942537"
                                    target="_blank"
                                    color="info"
                                >
                                    <SvgIconVK/>
                                </IconButton>
                                <IconButton
                                    href="https://tiktok.com/@prom1k"
                                    target="_blank"
                                >
                                    <SvgIconTikTok/>
                                </IconButton>
                                <IconButton
                                    href="https://instagram.com/prom1k"
                                    target="_blank"
                                >
                                    <SvgIconInstagram/>
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Divider sx={{my: 4}}/>
                <Typography variant="h2" align="center">Разное</Typography>
                <Typography align="center" mb={4}>Официальные страницы в интернете</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://store.steampowered.com/developer/isaev-space"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconSteam/>
                                        </Avatar>
                                    }
                                    title="Steam"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://www.rustore.ru/catalog/developer/b6b9d111"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconRuStore/>
                                        </Avatar>
                                    }
                                    title="RuStore"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://play.google.com/store/apps/dev?id=6648438553280805647"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconGooglePlay/>
                                        </Avatar>
                                    }
                                    title="Google Play"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://global.app.mi.com/developer?id=1083266"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconXiaomiGetApps/>
                                        </Avatar>
                                    }
                                    title="Xiaomi GetApps"
                                    subheader="ISAEV"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://music.yandex.ru/artist/22224668"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconYandexMusic/>
                                        </Avatar>
                                    }
                                    title="Яндекс Музыка"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://vk.com/artist/isaevspace"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconVKMusic/>
                                        </Avatar>
                                    }
                                    title="VK Музыка"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://zvuk.com/artist/212921545"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconZvuk/>
                                        </Avatar>
                                    }
                                    title="Звук"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://music.mts.ru/artist/22224668"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconMTSMusic/>
                                        </Avatar>
                                    }
                                    title="МТС Музыка"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://open.spotify.com/artist/1l5mHN7QScSLqH2gCDmc3K"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconSpotify/>
                                        </Avatar>
                                    }
                                    title="Spotify"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://music.apple.com/us/artist/isaev-space/1746917536"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconAppleMusic/>
                                        </Avatar>
                                    }
                                    title="Apple Music"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://lk.sferoom.space/artists/70042"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconSFEROOM/>
                                        </Avatar>
                                    }
                                    title="SFEROOM"
                                    subheader="ISAEV SPACE"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {/*<Grid item xs={12} sm={6} md={3}>*/}
                    {/*    <Card>*/}
                    {/*        <CardActionArea*/}
                    {/*            href="https://band.link/scanner?search=22224668&type=artist_id&service=yandex_music"*/}
                    {/*            target="_blank"*/}
                    {/*        >*/}
                    {/*            <CardHeader*/}
                    {/*                avatar={*/}
                    {/*                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>*/}
                    {/*                        <SvgIconBandLink/>*/}
                    {/*                    </Avatar>*/}
                    {/*                }*/}
                    {/*                title="BandLink Сканер"*/}
                    {/*                subheader="ISAEV SPACE"*/}
                    {/*            />*/}
                    {/*        </CardActionArea>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://taplink.cc/isaevspace"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconTaplink/>
                                        </Avatar>
                                    }
                                    title="Taplink"
                                    subheader="@isaevspace"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
                <Divider sx={{my: 4}}/>
                <Typography variant="h2" align="center">Поддержать</Typography>
                <Typography align="center" mb={4}>Разработка видеоигр</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardActionArea
                                href="https://boosty.to/nanaki"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconBoosty/>
                                        </Avatar>
                                    }
                                    title="Boosty"
                                    subheader="@nanaki"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardActionArea
                                href="https://tips.yandex.ru/guest/payment/7856410"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconYandexTips/>
                                        </Avatar>
                                    }
                                    title="Яндекс Чаевые"
                                    subheader="payment/7856410"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
                <Divider sx={{my: 4}}/>
                <Typography variant="h2" align="center">Неактивно</Typography>
                <Typography align="center" mb={4}>Тоже официальные страницы</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://www.ixbt.com/live/profile/350556/created/topics/"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconIXBT/>
                                        </Avatar>
                                    }
                                    title="iXBT"
                                    subheader="@NanakiLucis"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://stopgame.ru/user/Nanaki/blogs"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconStopGame/>
                                        </Avatar>
                                    }
                                    title="StopGame"
                                    subheader="@Nanaki"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://dailytelefrag.ru/user/75"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconTheDailyTelefrag/>
                                        </Avatar>
                                    }
                                    title="The Daily Telefrag"
                                    subheader="user/75"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://www.tumblr.com/isaev-space"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconTumblr sx={{color: useTheme().palette.text.primary}}/>
                                        </Avatar>
                                    }
                                    title="Tumblr"
                                    subheader="@isaev-space"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://rutube.ru/channel/38382219"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconRUTUBE/>
                                        </Avatar>
                                    }
                                    title="RUTUBE"
                                    subheader="channel/38382219"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://dzen.ru/isaevspace"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconDzen/>
                                        </Avatar>
                                    }
                                    title="Дзен"
                                    subheader="@isaevspace"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://nuum.ru/channel/isaev-space"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconNUUM/>
                                        </Avatar>
                                    }
                                    title="NUUM"
                                    subheader="@isaev-space"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://plvideo.ru/@isaevspace"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{
                                            color: useTheme().palette.text.primary,
                                            bgcolor: useTheme().palette.background.default
                                        }}>П</Avatar>
                                    }
                                    title="Платформа"
                                    subheader="@isaevspace"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://www.donationalerts.com/r/isaev_space"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconDonationAlerts/>
                                        </Avatar>
                                    }
                                    title="Donation Alerts"
                                    subheader="@isaev_space"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://memealerts.com/isaev_space"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconMemeAlerts/>
                                        </Avatar>
                                    }
                                    title="Meme Alerts"
                                    subheader="@isaev_space"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://sponsr.ru/isaevspace"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconSponsr/>
                                        </Avatar>
                                    }
                                    title="Sponsr"
                                    subheader="@isaevspace"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea
                                href="https://boomstarter.ru/profile/isaevspace"
                                target="_blank"
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                            <SvgIconBoomstarter/>
                                        </Avatar>
                                    }
                                    title="Boomstarter"
                                    subheader="@isaevspace"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
                <Divider sx={{my: 4}}/>
                <Typography variant="overline" align="center" display="block" sx={{mb: 4}}>
                    high tech , low life
                </Typography>
            </Container>
            <AppBar position="static">
                <Toolbar>
                    <Typography flexGrow={1}>© 2024</Typography>
                    <IconButton
                        href="https://t.me/isaev_inc"
                        target="_blank"
                        color="primary"
                    >
                        <SvgIconTelegram/>
                    </IconButton>
                    <IconButton
                        href="https://vk.com/isaev_inc"
                        target="_blank"
                        color="info"
                    >
                        <SvgIconVK/>
                    </IconButton>
                    <IconButton
                        href="https://youtube.com/@isaev_inc"
                        target="_blank"
                        color="error"
                    >
                        <SvgIconYouTube/>
                    </IconButton>
                    <IconButton
                        href="https://tiktok.com/@isaev_inc"
                        target="_blank"
                    >
                        <SvgIconTikTok/>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default App
