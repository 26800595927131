import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconRuStore: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 88 88">
            <path
                d="M42.24 88C22.3279 88 12.3718 88 6.18591 81.8141C-5.45447e-07 75.6282 -9.84393e-07 65.672 -1.86228e-06 45.7598L-2.01747e-06 42.2398C-2.89536e-06 22.3276 -3.3343e-06 12.3715 6.1859 6.18556C12.3718 7.53081e-06 22.3279 7.09933e-06 42.2399 6.23638e-06L42.24 6.23638e-06L45.76 6.08383e-06L45.76 6.08383e-06C65.6721 5.22087e-06 75.6282 4.7894e-06 81.8141 6.18555C88 12.3714 88 22.3276 88 42.2398L88 45.7598C88 65.672 88 75.6282 81.8141 81.8141C75.6282 88 65.6721 88 45.76 88L42.24 88Z"
                fill="#0077FF"/>
            <path
                d="M50.8869 54.2311C48.516 53.6388 46.8535 51.5179 46.8535 49.0854L46.8535 20.4275C46.8535 17.6646 49.4643 15.6383 52.1573 16.311L69.2113 20.5712C71.5823 21.1634 73.2448 23.2843 73.2448 25.7168L73.2448 54.3747C73.2448 57.1376 70.634 59.164 67.941 58.4913L50.8869 54.2311Z"
                fill="white"/>
            <path
                d="M18.7893 67.4269C16.4183 66.8346 14.7559 64.7137 14.7559 62.2812L14.7559 33.6233C14.7559 30.8604 17.3666 28.8341 20.0596 29.5068L37.1137 33.767C39.4846 34.3592 41.1471 36.4801 41.1471 38.9126L41.1471 67.5705C41.1471 70.3334 38.5363 72.3598 35.8433 71.687L18.7893 67.4269Z"
                fill="white"/>
            <path
                d="M34.8381 60.8292C32.4671 60.2369 30.8047 58.116 30.8047 55.6835L30.8047 27.0257C30.8047 24.2628 33.4155 22.2364 36.1085 22.9091L53.1625 27.1693C55.5335 27.7616 57.1959 29.8825 57.1959 32.315L57.1959 60.9728C57.1959 63.7358 54.5852 65.7621 51.8921 65.0894L34.8381 60.8292Z"
                fill="white"/>
            <path
                d="M45.9999 63.625V63.625C45.2531 63.4348 44.7216 62.7744 44.6955 62.0042L43.9536 40.1183C43.739 37.2217 41.5888 34.918 39.3419 34.2396C39.2157 34.2015 39.081 34.2528 39.0063 34.3613V34.3613C38.9304 34.4716 38.9597 34.6243 39.0656 34.7063C39.6205 35.1358 41.1476 36.5375 41.1476 38.9551L41.1431 62.3942L45.9999 63.625Z"
                fill="#2B71F6"/>
            <path
                d="M62.0528 57.0208V57.0208C61.3066 56.8341 60.7744 56.1757 60.7484 55.4069L60.0063 33.5226C59.7917 30.626 57.6416 28.3223 55.3946 27.6439C55.2685 27.6058 55.1338 27.6571 55.0591 27.7656V27.7656C54.9831 27.8759 55.0125 28.0286 55.1184 28.1106C55.6732 28.5401 57.2004 29.9418 57.2004 32.3594L57.1959 55.8065L62.0528 57.0208Z"
                fill="#2B71F6"/>
        </svg>
    </SvgIcon>
)

export default SvgIconRuStore
