import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconVKMusic: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            viewBox="45 67 100 100"
        >
            <path
                fill="#07F"
                d="M52.03 74.03c-6.82 6.819-7.023 17.67-7.03 38.969 19.088 0 31.006-8.194 41.46-15.383C94.626 92.001 101.9 87 111 87c13.546 0 25.373 9.578 31.644 14.656.884.716 1.658 1.343 2.311 1.841-.198-15.187-1.263-23.745-6.984-29.467C130.941 67 119.628 67 97.001 67h-4c-22.628 0-33.942 0-40.971 7.03ZM90.06 166.999 93 167h4l2.94-.001h-9.88Z"
            />
            <path
                fill="#F45FFF"
                d="M45 112.999c19.088 0 31.006-8.194 41.46-15.383C94.626 92.001 101.899 87 111 87c13.545 0 25.373 9.578 31.644 14.656.884.716 1.658 1.343 2.311 1.841.045 3.473.045 7.292.045 11.504v4c0 22.627 0 33.941-7.029 40.971-6.719 6.718-17.35 7.015-38.032 7.028h-9.878c-20.682-.013-31.313-.31-38.032-7.028C45 152.941 45 141.627 45 119V112.999Z"
            />
            <path
                fill="#fff"
                d="M122.5 117.975c0-2.471 2.029-4.475 4.5-4.475 2.472 0 4.5 2.004 4.5 4.475v18.05c0 2.472-2.028 4.475-4.5 4.475-2.471 0-4.5-2.003-4.5-4.475v-18.05ZM106.501 106.475c0-2.471 2.029-4.475 4.5-4.475s4.5 2.004 4.5 4.475v41.05c0 2.472-2.029 4.475-4.5 4.475s-4.5-2.003-4.5-4.475v-41.05ZM90.501 117.975c0-2.471 2.028-4.475 4.5-4.475 2.471 0 4.5 2.004 4.5 4.475v18.05c0 2.471-2.029 4.475-4.5 4.475-2.472 0-4.5-2.004-4.5-4.475v-18.05ZM74.499 124.475c0-2.472 2.029-4.475 4.5-4.475 2.472 0 4.5 2.003 4.5 4.475v5.05c0 2.471-2.028 4.475-4.5 4.475-2.471 0-4.5-2.004-4.5-4.475v-5.05ZM58.499 124.475c0-2.472 2.029-4.475 4.5-4.475s4.5 2.003 4.5 4.475v5.05c0 2.471-2.028 4.475-4.5 4.475-2.471 0-4.5-2.004-4.5-4.475v-5.05Z"
                className="style_staticBar__a1Jzs"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconVKMusic
