import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconStopGame: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 43 38">
            <path
                d="M32 0H18c-2.2.4-2.4 2.9-2.7 4.6h18.1l.5-3c0-.9-1-1.6-1.9-1.6zM21.4 10H15c-.5-.2-.8.3-.9.7l-1 5.7 20 .1c1.4 0 2.5 1.5 2.2 3L34 28h4c1.3-.1 2.2-1.4 2.4-2.6l2.3-13c.1-.7.2-1.5-.4-2-.5-.6-1.3-.6-2-.6h-19zM6 10H4.9c-1.3 0-2.3 1.3-2.5 2.5L0 26c-.2 1.1.8 2.2 2 2 8.5.1 17 0 25.6 0 .4.1 1 0 1-.5.5-1.9.7-3.8 1.1-5.7H11.8c-1.2-.1-2.5.2-3.5-.6-.8-.6-1-1.8-.8-2.8L9.3 10H6zm12.9 23.4h-9c-.2.9-.4 1.8-.4 2.7.2.9 1 1.5 2 1.4h12.8c.8 0 1.7 0 2.4-.6 1-.9 1-2.3 1.2-3.5h-9z"
                fill="#ec2227"/>
        </svg>
    </SvgIcon>
)

export default SvgIconStopGame
