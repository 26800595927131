import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconBoosty: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            viewBox="0 0 70 86"
        >
            <path
                fill="url(#aboo)"
                fillRule="evenodd"
                d="M16.774 0 2.228 51.282l-.521 1.804c-5.01 17.634.713 32.539 21.12 32.77q3.92-10.029 10.85-27.687H22.615l11.853-41.983q.044-.15.116-.287L39.098 0zm33.041 46.363L22.908 85.857h.415c19.881 0 40.302-14.991 45.348-32.776 4.699-16.53-3.294-30.2-20.34-32.017l-9.932 25.299z"
                clipRule="evenodd"
            />
            <defs>
                <linearGradient
                    id="aboo"
                    x1={6.421}
                    x2={-34.538}
                    y1={-6.469}
                    y2={88.152}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF7829" />
                    <stop offset={0.28} stopColor="#F0692A" />
                    <stop offset={0.63} stopColor="#F15E2C" />
                    <stop offset={1} stopColor="#F15A2C" />
                </linearGradient>
            </defs>
        </svg>
    </SvgIcon>
)

export default SvgIconBoosty
