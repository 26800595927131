import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconDzen: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 24 24">
            <path
                fill="#000"
                d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12"
            />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M11.64 0c-.06 4.865-.392 7.599-2.217 9.423C7.6 11.248 4.865 11.58 0 11.64v.72c4.865.06 7.599.392 9.423 2.217C11.248 16.4 11.58 19.135 11.64 24h.72c.06-4.865.392-7.599 2.217-9.423C16.4 12.752 19.135 12.42 24 12.36v-.72c-4.865-.06-7.599-.392-9.423-2.217C12.752 7.6 12.42 4.865 12.36 0z"
                clipRule="evenodd"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconDzen
