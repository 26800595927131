import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconZvuk: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            viewBox="0 0 64 64"
        >
            <g clipPath="url(#a)">
                <path
                    fill="#fff"
                    d="M64 32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32Z"
                />
                <path
                    fill="#08090A"
                    fillRule="evenodd"
                    d="M28.79 47.163c.105.508.158.762-.001.94-.16.18-.424.156-.955.109-2.969-.265-4.629-.809-5.928-1.347a8.816 8.816 0 0 1-4.77-4.771c-.539-1.299-1.083-2.96-1.347-5.928-.048-.53-.071-.796.107-.955.179-.159.433-.106.941-.001A15.432 15.432 0 0 1 28.79 47.163Zm5.739 1.158c-.413.02-.62.029-.76-.089-.142-.117-.173-.331-.233-.76-1.245-8.8-8.207-15.763-17.008-17.008-.429-.06-.643-.09-.76-.232-.118-.142-.108-.348-.089-.76.08-1.728.244-3.085.454-4.192.069-.364.104-.546.248-.656.144-.11.33-.097.703-.069 11.936.898 21.463 10.425 22.36 22.361.029.373.043.56-.068.704-.11.144-.292.178-.656.247-1.107.21-2.464.373-4.191.454Zm10.699-3.653c-.47.514-.705.77-.99.685-.286-.085-.347-.465-.47-1.225-1.974-12.25-11.647-21.922-23.896-23.896-.76-.122-1.14-.184-1.225-.47-.085-.285.172-.52.686-.99a8.816 8.816 0 0 1 2.573-1.637c1.81-.75 4.32-1.511 10.094-1.511 5.773 0 8.285.762 10.094 1.51a8.816 8.816 0 0 1 4.771 4.772c.75 1.81 1.511 4.32 1.511 10.094 0 5.773-.761 8.285-1.51 10.094a8.813 8.813 0 0 1-1.638 2.574ZM11 32c0-6.262.84-9.393 1.863-11.863a13.44 13.44 0 0 1 7.274-7.274C22.607 11.84 25.737 11 32 11c6.262 0 9.394.84 11.864 1.863a13.44 13.44 0 0 1 7.273 7.274C52.16 22.607 53 25.737 53 32c0 6.262-.84 9.393-1.863 11.863a13.44 13.44 0 0 1-7.273 7.274C41.394 52.16 38.261 53 32 53c-6.262 0-9.393-.84-11.863-1.863a13.44 13.44 0 0 1-7.274-7.274C11.84 41.393 11 38.263 11 32Z"
                    clipRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h64v64H0z" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
)

export default SvgIconZvuk
