import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconXiaomiGetApps: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 52 52">
            <defs>
                <path
                    d="M15.9510286,-6.44440649e-16 L36.0489714,6.44440649e-16 C41.5954915,-3.74438575e-16 43.6067938,0.577507909 45.6345186,1.66194704 C47.6622435,2.74638616 49.2536138,4.33775651 50.338053,6.36548137 C51.4224921,8.39320624 52,10.4045085 52,15.9510286 L52,36.0489714 C52,41.5954915 51.4224921,43.6067938 50.338053,45.6345186 C49.2536138,47.6622435 47.6622435,49.2536138 45.6345186,50.338053 C43.6067938,51.4224921 41.5954915,52 36.0489714,52 L15.9510286,52 C10.4045085,52 8.39320624,51.4224921 6.36548137,50.338053 C4.33775651,49.2536138 2.74638616,47.6622435 1.66194704,45.6345186 C0.577507909,43.6067938 2.49625717e-16,41.5954915 -4.29627099e-16,36.0489714 L4.29627099e-16,15.9510286 C-2.49625717e-16,10.4045085 0.577507909,8.39320624 1.66194704,6.36548137 C2.74638616,4.33775651 4.33775651,2.74638616 6.36548137,1.66194704 C8.39320624,0.577507909 10.4045085,3.74438575e-16 15.9510286,-6.44440649e-16 Z"
                    id="path-1"/>
                <path
                    d="M15.9510286,-6.44440649e-16 L36.0489714,6.44440649e-16 C41.5954915,-3.74438575e-16 43.6067938,0.577507909 45.6345186,1.66194704 C47.6622435,2.74638616 49.2536138,4.33775651 50.338053,6.36548137 C51.4224921,8.39320624 52,10.4045085 52,15.9510286 L52,36.0489714 C52,41.5954915 51.4224921,43.6067938 50.338053,45.6345186 C49.2536138,47.6622435 47.6622435,49.2536138 45.6345186,50.338053 C43.6067938,51.4224921 41.5954915,52 36.0489714,52 L15.9510286,52 C10.4045085,52 8.39320624,51.4224921 6.36548137,50.338053 C4.33775651,49.2536138 2.74638616,47.6622435 1.66194704,45.6345186 C0.577507909,43.6067938 2.49625717e-16,41.5954915 -4.29627099e-16,36.0489714 L4.29627099e-16,15.9510286 C-2.49625717e-16,10.4045085 0.577507909,8.39320624 1.66194704,6.36548137 C2.74638616,4.33775651 4.33775651,2.74638616 6.36548137,1.66194704 C8.39320624,0.577507909 10.4045085,3.74438575e-16 15.9510286,-6.44440649e-16 Z"
                    id="path-3"/>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"/>
                    </mask>
                    <use fill="#FFFFFF" xlinkHref="#path-1"/>
                    <path
                        d="M37.2273902,59 L-1.85293557,59 C-4.69537501,59 -7,56.6922933 -7,53.8454172 L-7,43.3718205 L7.35012475,28.9276486 L37.2273902,59"
                        id="Fill-11" fill="#00E691" mask="url(#mask-2)"/>
                    <path
                        d="M46.1103546,23.5839793 L15.7390181,-7 L53.8519668,-7 C56.6952256,-7 59,-4.69059748 59,-1.84162939 L59,10.6034555 L46.1103546,23.5839793"
                        id="Fill-12" fill="#00CCFF" mask="url(#mask-2)"/>
                    <path
                        d="M53.8424817,58.9431525 L43.3051338,58.9431525 L29.7235142,45.3060224 L59,15.9095607 L59,53.7896758 C59,54.3596503 58.9076712,54.9074649 58.7369403,55.4202358 C58.0540165,57.4671967 56.1202696,58.9431525 53.8424817,58.9431525"
                        id="Fill-14" fill="#8F61FF" mask="url(#mask-2)"/>
                    <path
                        d="M-7,37.2842377 L-7,-1.84995142 C-7,-2.37474137 -6.92120218,-2.88150615 -6.77545196,-3.35840065 C-6.13013385,-5.46683054 -4.16894361,-7 -1.84930053,-7 L10.3293406,-7 L23.6976744,6.43441672 L-7,37.2842377"
                        id="Fill-16" fill="#FF5C69" mask="url(#mask-2)"/>
                    <polygon points="24.6693726 32.0018921 24.6693726 17 27.3306274 17 27.3306274 32.0018921"
                             id="Fill-18" fill="#00E08A" fillRule="nonzero" mask="url(#mask-2)"/>
                    <mask id="mask-4" fill="white">
                        <use xlinkHref="#path-3"/>
                    </mask>
                    <path
                        d="M36.0489714,0.33 C41.5020364,0.33 43.4840876,0.886112843 45.478891,1.95294539 C47.4482567,3.00617366 48.9938263,4.55174335 50.0470546,6.521109 C51.1138872,8.51591243 51.67,10.4979636 51.67,15.9510286 L51.67,15.9510286 L51.67,36.0489714 C51.67,41.5020364 51.1138872,43.4840876 50.0470546,45.478891 C48.9938263,47.4482567 47.4482567,48.9938263 45.478891,50.0470546 C43.4840876,51.1138872 41.5020364,51.67 36.0489714,51.67 L36.0489714,51.67 L15.9510286,51.67 C10.4979636,51.67 8.51591243,51.1138872 6.521109,50.0470546 C4.55174335,48.9938263 3.00617366,47.4482567 1.95294539,45.478891 C0.886112843,43.4840876 0.33,41.5020364 0.33,36.0489714 L0.33,36.0489714 L0.33,15.9510286 C0.33,10.4979636 0.886112843,8.51591243 1.95294539,6.521109 C3.00617366,4.55174335 4.55174335,3.00617366 6.521109,1.95294539 C8.51591243,0.886112843 10.4979636,0.33 15.9510286,0.33 L15.9510286,0.33 L36.0489714,0.33 Z"
                        strokeOpacity="0.08" stroke="#000000" strokeWidth="0.66"/>
                    <polyline points="18.2177943 25.498148 26 33.2803537 33.7822057 25.498148"
                              stroke="#0FCFFF" strokeWidth="2.66" mask="url(#mask-4)"/>
                </g>
            </g>
        </svg>
    </SvgIcon>
)

export default SvgIconXiaomiGetApps
