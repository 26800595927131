import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconTaplink: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"

            viewBox="0 0 76 76"
        >
            <path fill="#fff" d="M38 0C17 0 0 17 0 38s17 38 38 38 38-17 38-38S59 0 38 0zm0 72C19.2 72 4 56.8 4 38S19.2 4 38 4s34 15.2 34 34-15.2 34-34 34zm19.5-34c0 1.1-.9 2-2 2h-35c-1.1 0-2-.9-2-2s.9-2 2-2h35c1.1 0 2 .9 2 2zm0 12c0 1.1-.9 2-2 2h-35c-1.1 0-2-.9-2-2s.9-2 2-2h35c1.1 0 2 .9 2 2zm0-24c0 1.1-.9 2-2 2h-35c-1.1 0-2-.9-2-2s.9-2 2-2h35c1.1 0 2 .9 2 2z" />
        </svg>
    </SvgIcon>
)

export default SvgIconTaplink
