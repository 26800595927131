import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconDTF: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 70 23">
            <path
                d="M0 0h14.5c5.014 0 9.001 3.984 9 8.883v5.234c0 4.899-3.986 8.883-9 8.883H0V0Zm14.5 17.216c1.749 0 3-1.39 3-3.099V8.883c0-1.712-1.252-3.1-3-3.1H6v11.433h8.5Z"
            />
            <path
                d="M25 6h8v17h7V6h8V0H25v6ZM70 6V0H51v23h6.5v-7H67v-6h-9.5V6H70Z"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconDTF
