import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconSteam: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            fill="none"
            viewBox="0 0 32 32"
        >
            <path
                fill="url(#steam)"
                d="M2.57 20.027C4.3 25.797 9.654 30 15.99 30 23.728 30 30 23.732 30 16S23.727 2 15.99 2C8.567 2 2.493 7.772 2.013 15.07c0 2.097 0 2.972.558 4.957Z"
            />
            <path
                fill="#fff"
                d="m15.27 12.563-3.427 4.976a3.891 3.891 0 0 0-2.297.636l-7.528-3.097s-.174 2.864.552 4.998l5.321 2.195a3.88 3.88 0 0 0 2.295 2.743 3.887 3.887 0 0 0 5.38-3.691l5.024-3.501a5.33 5.33 0 0 0 5.321-5.328 5.329 5.329 0 0 0-5.32-5.327c-2.836 0-5.48 2.474-5.32 5.396Zm-.823 10.015a3 3 0 0 1-3.92 1.617 2.979 2.979 0 0 1-1.542-1.471l1.732.717a2.208 2.208 0 0 0 1.697-4.077l-1.79-.742a2.981 2.981 0 0 1 2.21.034c.74.308 1.314.887 1.618 1.627.304.74.303 1.557-.005 2.295Zm6.143-6.535a3.552 3.552 0 0 1-3.544-3.55 3.552 3.552 0 0 1 3.544-3.548 3.552 3.552 0 0 1 3.547 3.549c0 1.957-1.591 3.55-3.547 3.55Zm-2.656-3.555a2.665 2.665 0 0 1 2.662-2.666 2.666 2.666 0 0 1 0 5.332 2.664 2.664 0 0 1-2.662-2.666Z"
            />
            <defs>
                <linearGradient
                    id="steam"
                    x1={16.006}
                    x2={16.006}
                    y1={2}
                    y2={30}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111D2E" />
                    <stop offset={0.212} stopColor="#051839" />
                    <stop offset={0.407} stopColor="#0A1B48" />
                    <stop offset={0.581} stopColor="#132E62" />
                    <stop offset={0.738} stopColor="#144B7E" />
                    <stop offset={0.873} stopColor="#136497" />
                    <stop offset={1} stopColor="#1387B8" />
                </linearGradient>
            </defs>
        </svg>
    </SvgIcon>
)

export default SvgIconSteam
