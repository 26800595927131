import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconSpotify: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 286 286">
            <g fill="none" fillRule="evenodd">
                <circle
                    cx={137.5}
                    cy={137.5}
                    r={137.5}
                    fill="#40DD78"
                    transform="translate(10 5)"
                />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M201.508 194.533c-2.168 3.869-5.394 4.35-8.897 2.188-15.309-9.475-32.785-15.743-51.671-17.803-19.392-2.113-38.207.445-55.452 6.751-1.85.68-5.755.64-6.969-4.74-1.214-5.39 1.835-8.024 3.495-8.615 18.802-6.736 39.244-9.492 60.323-7.197 20.712 2.259 40.228 8.51 56.658 19.482 2.59 1.731 4.68 6.062 2.513 9.934Zm13.527-30.88c-1.433 1.679-3.51 2.726-5.826 2.475-.64-.07-1.202-.349-1.769-.584-18.805-11.18-40.062-18.622-63.006-21.123-22.774-2.478-44.91.156-65.418 6.924-.735.184-1.49.328-2.284.241-2.358-.256-4.207-1.778-5.26-3.787l-.892-4.205c.236-2.105 1.37-3.828 2.922-4.97 22.62-7.694 47.115-10.753 72.333-8.006 25.21 2.745 48.542 10.995 69.121 23.408a7.256 7.256 0 0 1 1.877 5.525l-1.798 4.103ZM68.888 96.582c25.626-7.604 53.016-10.43 81.142-7.364 27.87 3.037 53.873 11.56 77.24 24.323 3.125 2.102 5.089 5.785 4.675 9.889-.59 5.816-5.619 10.028-11.22 9.42-.784-.088-1.505-.348-2.214-.609-21.327-11.728-45.1-19.554-70.576-22.33-25.495-2.78-50.338-.245-73.558 6.653-.734.087-1.47.18-2.236.095-5.6-.61-9.674-5.816-9.084-11.629.387-3.828 2.707-6.93 5.83-8.448Z"
                />
            </g>
        </svg>
    </SvgIcon>
)

export default SvgIconSpotify
