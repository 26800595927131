import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconZip: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path
                fill="none"
                stroke="#fff"
                strokeWidth={2}
                d="M4.998 9V1H19.5L23 4.5V23H4M18 1v5h5M2 13h5v1l-4 4v1h5m3-7v8-8Zm4 1v7-7Zm5 2a2 2 0 0 0-2-2h-3v4h3a2 2 0 0 0 2-2Z"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconZip
