import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconBandLink: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            fill="none"
            viewBox="0 0 32 32"
        >
            <g clipPath="url(#a)">
                <g clipPath="url(#b)">
                    <path
                        fill="#F8253E"
                        d="M0 13.049C0 5.842 5.842 0 13.05 0h18.952v18.951c0 7.207-5.843 13.049-13.05 13.049H0V13.049Z"
                    />
                    <path
                        fill="#FDFDFD"
                        fillRule="evenodd"
                        d="M16 21.925 10.129 25l1.121-6.512-4.75-4.613 6.564-.95L16 7l2.936 5.925 6.564.95-4.75 4.613L21.871 25 16 21.925Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h139.98v32H0z" />
                </clipPath>
                <clipPath id="b">
                    <path fill="#fff" d="M0 0h32.002v32H0z" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
)

export default SvgIconBandLink
