import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconHuaweiAppGallery: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 36 36">
            <defs>
                <linearGradient id="linearGradient-1" gradientUnits="userSpaceOnUse" x1="18" y1="0" x2="18" y2="36">
                    <stop stopColor="#FB6361" offset="0%"/>
                    <stop stopColor="#ED3E45" offset="100%"/>
                </linearGradient>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-2,-2)">
                <g transform="translate(2,2)">
                    <path fill="url(#linearGradient-1)"
                          d="M 10.101,0 C 2.7051128,0 0,2.704641 0,10.099029 V 25.900971 C 0,33.295359 2.7051128,36 10.101,36 H 25.894186 C 33.289863,36 36,33.295359 36,25.900971 V 10.099029 C 36,2.704641 33.294887,0 25.899,0 Z"/>
                    <path fill="#ffffff"
                          d="m 15.703052,20.879251 h 1.445267 l -0.725093,-1.68629 z m -0.355892,0.8498 -0.428806,0.981162 h -0.976243 l 2.076035,-4.709924 h 0.843724 l 2.067644,4.709924 H 17.92723 l -0.423308,-0.981162 z m 15.709293,0.97769 H 32 V 18 h -0.943547 z m -3.755381,-2.021928 h 1.73924 v -0.858191 h -1.73924 v -0.96467 h 2.524806 v -0.85848 h -3.468064 v 4.706451 h 3.558917 v -0.85848 H 27.301072 Z M 23.552056,21.24093 22.482355,18 h -0.780647 l -1.069701,3.24093 -1.041055,-3.238326 h -1.017908 l 1.642888,4.710213 h 0.791353 l 1.071437,-3.093944 1.071437,3.093944 h 0.798297 l 1.638548,-4.710213 H 24.595426 Z M 12.501762,20.697833 c 0,0.76618 -0.380486,1.1756 -1.071437,1.1756 -0.694712,0 -1.077223,-0.421283 -1.077223,-1.208296 V 18.003183 H 9.3968234 v 2.69465 c 0,1.32548 0.7366666,2.085584 2.0204816,2.085584 1.296545,0 2.039867,-0.774571 2.039867,-2.124935 v -2.658193 h -0.95541 z M 7.1153548,18.000289 h 0.9556995 v 4.712817 H 7.1153548 V 20.799103 H 4.9562782 v 1.914003 H 4 v -4.712817 h 0.9562782 v 1.900983 h 2.1590766 z"/>
                    <path fill="#ffffff"
                          d="M 18,12 C 14.691262,12 12,9.3084491 12,6 h 0.847512 c 0,2.8408565 2.311632,5.152199 5.152488,5.152199 2.840856,0 5.152488,-2.3113425 5.152488,-5.152199 H 24 c 0,3.3084491 -2.691551,6 -6,6"/>
                </g>
            </g>
        </svg>
    </SvgIcon>
)

export default SvgIconHuaweiAppGallery
