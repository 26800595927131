import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconIsaevSpace: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 500 500">
            <path
                d="M147.47,147.47A145,145,0,0,1,372.63,327.41l3.95,3.11A150,150,0,0,0,143.93,143.93q-5.37,5.37-10.08,11.14l3.56,3.55A144,144,0,0,1,147.47,147.47Z"
                />
            <path
                d="M362.59,341.38A145,145,0,0,1,127.37,172.59l-4-3.11A150,150,0,0,0,356.07,356.07q5.37-5.37,10.08-11.14Z"
                />
            <path
                d="M401.63,380.42,395,373.74l-14.62-14.61h0L140.87,119.66A170,170,0,0,1,392.38,342.93l23.67,18.59c52.35-77.76,44.15-184.16-24.63-252.94-65.32-65.32-164.58-76-241-32.07-.71.41-1.42.81-2.14,1.19C125,90.27,96.67,89.25,73.84,75.84L27.26,48.47l71.11,71.11,6.67,6.68,14.62,14.61h0L359.13,380.34A170,170,0,0,1,107.62,157.07L84,138.48C31.6,216.24,39.8,322.64,108.58,391.42c65.32,65.32,164.58,76,241,32.07.71-.41,1.42-.81,2.14-1.19,23.3-12.57,51.59-11.55,74.42,1.86l46.58,27.37Z"
                />
        </svg>
    </SvgIcon>
)

export default SvgIconIsaevSpace
