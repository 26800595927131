import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconTheDailyTelefrag: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 155 115">
            <g fillRule="evenodd">
                <path
                    fill="#215483"
                    d="M77.742 114.12c42.311 0 76.743-25.4 76.743-56.704 0-31.012-34.432-56.411-76.743-56.411C35.43 1.005 1 26.404 1 57.415 1 88.72 35.43 114.12 77.742 114.12"
                />
                <path
                    fill="#809db3"
                    d="M77.79 110.862c40.302 0 73.098-23.902 73.098-53.364 0-29.186-32.796-53.09-73.097-53.09-40.303 0-73.098 23.904-73.098 53.09 0 29.462 32.795 53.364 73.098 53.364"
                />
                <path
                    fill="#215483"
                    d="M72.508 18.862v83.94L56.666 30.537c-19.178 5.836-32.52 19.176-32.52 34.743 0 15.287 13.065 28.627 31.966 34.464-24.459-6.392-42.247-23.07-42.247-42.246 0-23.626 25.848-43.084 58.643-45.027v6.391zm10.563-6.391c32.797 1.943 58.646 23.068 58.646 45.027 0 21.678-19.177 37.52-45.86 43.08 20.568-5.282 35.3-19.734 35.3-35.298 0-15.842-13.342-28.63-32.243-34.466l-3.057 14.453c12.228 4.446 21.958 11.118 22.515 18.067.553 6.671-1.668 10.56-9.73 15.566.833-1.668 1.944-.835 1.944-5.838 0-5.283-7.78-10.282-18.064-13.34l-9.45 43.08V12.471"
                />
            </g>
        </svg>
    </SvgIcon>
)

export default SvgIconTheDailyTelefrag
