import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconIXBT: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 40 40">
            <path
                d="M0 12C0 6.34315 0 3.51472 1.75736 1.75736C3.51472 0 6.34315 0 12 0H28C33.6569 0 36.4853 0 38.2426 1.75736C40 3.51472 40 6.34315 40 12V28C40 33.6569 40 36.4853 38.2426 38.2426C36.4853 40 33.6569 40 28 40H12C6.34315 40 3.51472 40 1.75736 38.2426C0 36.4853 0 33.6569 0 28V12Z"
                fill="#5A25F4"/>
            <path
                d="M14.7389 9.34225V16.2861H17.2512V18.3373H9.65515V16.2861H12.1832V9.34225H9.80688V7.29102H17.0995V9.34225H14.7389Z"
                fill="white"/>
            <path
                d="M27.9232 18.3373L25.2572 14.5028L22.6385 18.3373H19.7045L23.7912 12.7196L19.9074 7.29102H22.8099L25.3498 10.8733L27.8444 7.29102H30.605L26.7547 12.625L30.8729 18.3373H27.9232Z"
                fill="white"/>
            <path
                d="M19.7852 29.6771C19.7852 31.5864 18.2699 32.6899 15.3675 32.6899H9.65515V21.6436H15.0522C17.8148 21.6436 19.2335 22.7963 19.2335 24.5165C19.2335 25.6199 18.666 26.4731 17.7655 26.946C18.997 27.3401 19.7852 28.2879 19.7852 29.6771ZM12.1951 23.5706V26.1736H14.735C15.9803 26.1736 16.6601 25.7322 16.6601 24.8633C16.6601 23.9943 15.9822 23.5687 14.735 23.5687H12.1951V23.5706ZM17.2138 29.4091C17.2138 28.479 16.5044 28.0357 15.1783 28.0357H12.1951V30.7647H15.1783C16.5044 30.7647 17.2138 30.3549 17.2138 29.4071V29.4091Z"
                fill="white"/>
            <path
                d="M24.9044 23.7283H21.7714V21.6455H30.5931V23.7283H27.4601V32.6898H24.9044V23.7283Z"
                fill="white"/>
        </svg>
    </SvgIcon>
)

export default SvgIconIXBT
