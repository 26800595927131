import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconReddit: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            viewBox="0 0 216 216"
        >
            <defs>
                <radialGradient
                    id="snoo-radial-gragient"
                    cx={169.75}
                    cy={92.19}
                    r={50.98}
                    fx={169.75}
                    fy={92.19}
                    gradientTransform="matrix(1 0 0 .87 0 11.64)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#feffff" />
                    <stop offset={0.4} stopColor="#feffff" />
                    <stop offset={0.51} stopColor="#f9fcfc" />
                    <stop offset={0.62} stopColor="#edf3f5" />
                    <stop offset={0.7} stopColor="#dee9ec" />
                    <stop offset={0.72} stopColor="#d8e4e8" />
                    <stop offset={0.76} stopColor="#ccd8df" />
                    <stop offset={0.8} stopColor="#c8d5dd" />
                    <stop offset={0.83} stopColor="#ccd6de" />
                    <stop offset={0.85} stopColor="#d8dbe2" />
                    <stop offset={0.88} stopColor="#ede3e9" />
                    <stop offset={0.9} stopColor="#ffebef" />
                </radialGradient>
                <radialGradient
                    xlinkHref="#snoo-radial-gragient"
                    id="snoo-radial-gragient-2"
                    cx={47.31}
                    r={50.98}
                    fx={47.31}
                />
                <radialGradient
                    xlinkHref="#snoo-radial-gragient"
                    id="snoo-radial-gragient-3"
                    cx={109.61}
                    cy={85.59}
                    r={153.78}
                    fx={109.61}
                    fy={85.59}
                    gradientTransform="matrix(1 0 0 .7 0 25.56)"
                />
                <radialGradient
                    id="snoo-radial-gragient-4"
                    cx={-6.01}
                    cy={64.68}
                    r={12.85}
                    fx={-6.01}
                    fy={64.68}
                    gradientTransform="matrix(1.07 0 0 1.55 81.08 27.26)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#f60" />
                    <stop offset={0.5} stopColor="#ff4500" />
                    <stop offset={0.7} stopColor="#fc4301" />
                    <stop offset={0.82} stopColor="#f43f07" />
                    <stop offset={0.92} stopColor="#e53812" />
                    <stop offset={1} stopColor="#d4301f" />
                </radialGradient>
                <radialGradient
                    xlinkHref="#snoo-radial-gragient-4"
                    id="snoo-radial-gragient-5"
                    cx={-73.55}
                    cy={64.68}
                    r={12.85}
                    fx={-73.55}
                    fy={64.68}
                    gradientTransform="matrix(-1.07 0 0 1.55 62.87 27.26)"
                />
                <radialGradient
                    id="snoo-radial-gragient-6"
                    cx={107.93}
                    cy={166.96}
                    r={45.3}
                    fx={107.93}
                    fy={166.96}
                    gradientTransform="matrix(1 0 0 .66 0 57.4)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#172e35" />
                    <stop offset={0.29} stopColor="#0e1c21" />
                    <stop offset={0.73} stopColor="#030708" />
                    <stop offset={1} />
                </radialGradient>
                <radialGradient
                    xlinkHref="#snoo-radial-gragient"
                    id="snoo-radial-gragient-7"
                    cx={147.88}
                    cy={32.94}
                    r={39.77}
                    fx={147.88}
                    fy={32.94}
                    gradientTransform="matrix(1 0 0 .98 0 .54)"
                />
                <radialGradient
                    id="snoo-radial-gragient-8"
                    cx={131.31}
                    cy={73.08}
                    r={32.6}
                    fx={131.31}
                    fy={73.08}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.48} stopColor="#7a9299" />
                    <stop offset={0.67} stopColor="#172e35" />
                    <stop offset={0.75} />
                    <stop offset={0.82} stopColor="#172e35" />
                </radialGradient>
                <style>
                    {
                        ".snoo-cls-11,.snoo-cls-9{stroke-width:0}.snoo-cls-9{fill:#842123}.snoo-cls-11{fill:#ffc49c}"
                    }
                </style>
            </defs>
            <path
                d="M108 0C48.35 0 0 48.35 0 108c0 29.82 12.09 56.82 31.63 76.37l-20.57 20.57C6.98 209.02 9.87 216 15.64 216H108c59.65 0 108-48.35 108-108S167.65 0 108 0Z"
                style={{
                    fill: "#ff4500",
                    strokeWidth: 0,
                }}
            />
            <circle
                cx={169.22}
                cy={106.98}
                r={25.22}
                style={{
                    strokeWidth: 0,
                    fill: "url(#snoo-radial-gragient)",
                }}
            />
            <circle
                cx={46.78}
                cy={106.98}
                r={25.22}
                style={{
                    fill: "url(#snoo-radial-gragient-2)",
                    strokeWidth: 0,
                }}
            />
            <ellipse
                cx={108.06}
                cy={128.64}
                rx={72}
                ry={54}
                style={{
                    fill: "url(#snoo-radial-gragient-3)",
                    strokeWidth: 0,
                }}
            />
            <path
                d="M86.78 123.48c-.42 9.08-6.49 12.38-13.56 12.38s-12.46-4.93-12.04-14.01c.42-9.08 6.49-15.02 13.56-15.02s12.46 7.58 12.04 16.66Z"
                style={{
                    fill: "url(#snoo-radial-gragient-4)",
                    strokeWidth: 0,
                }}
            />
            <path
                d="M129.35 123.48c.42 9.08 6.49 12.38 13.56 12.38s12.46-4.93 12.04-14.01c-.42-9.08-6.49-15.02-13.56-15.02s-12.46 7.58-12.04 16.66Z"
                style={{
                    fill: "url(#snoo-radial-gragient-5)",
                    strokeWidth: 0,
                }}
            />
            <ellipse
                cx={79.63}
                cy={116.37}
                className="snoo-cls-11"
                rx={2.8}
                ry={3.05}
            />
            <ellipse
                cx={146.21}
                cy={116.37}
                className="snoo-cls-11"
                rx={2.8}
                ry={3.05}
            />
            <path
                d="M108.06 142.92c-8.76 0-17.16.43-24.92 1.22-1.33.13-2.17 1.51-1.65 2.74 4.35 10.39 14.61 17.69 26.57 17.69s22.23-7.3 26.57-17.69c.52-1.23-.33-2.61-1.65-2.74-7.77-.79-16.16-1.22-24.92-1.22Z"
                style={{
                    fill: "url(#snoo-radial-gragient-6)",
                    strokeWidth: 0,
                }}
            />
            <circle
                cx={147.49}
                cy={49.43}
                r={17.87}
                style={{
                    fill: "url(#snoo-radial-gragient-7)",
                    strokeWidth: 0,
                }}
            />
            <path
                d="M107.8 76.92c-2.14 0-3.87-.89-3.87-2.27 0-16.01 13.03-29.04 29.04-29.04 2.14 0 3.87 1.73 3.87 3.87s-1.73 3.87-3.87 3.87c-11.74 0-21.29 9.55-21.29 21.29 0 1.38-1.73 2.27-3.87 2.27Z"
                style={{
                    fill: "url(#snoo-radial-gragient-8)",
                    strokeWidth: 0,
                }}
            />
            <path
                d="M62.82 122.65c.39-8.56 6.08-14.16 12.69-14.16 6.26 0 11.1 6.39 11.28 14.33.17-8.88-5.13-15.99-12.05-15.99s-13.14 6.05-13.56 15.2c-.42 9.15 4.97 13.83 12.04 13.83h.52c-6.44-.16-11.3-4.79-10.91-13.2ZM153.3 122.65c-.39-8.56-6.08-14.16-12.69-14.16-6.26 0-11.1 6.39-11.28 14.33-.17-8.88 5.13-15.99 12.05-15.99 7.07 0 13.14 6.05 13.56 15.2.42 9.15-4.97 13.83-12.04 13.83h-.52c6.44-.16 11.3-4.79 10.91-13.2Z"
                className="snoo-cls-9"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconReddit
