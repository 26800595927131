import {Badge, BadgeProps, styled} from "@mui/material";

const BadgeH1 = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        fontSize: '2rem',
        padding: 0
    }
}))

export default BadgeH1
