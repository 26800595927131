import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconMailYandex: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            fill="none"
            viewBox="0 0 50 50"
        >
            <path
                fill="#FFCA38"
                fillRule="evenodd"
                d="M.9 10.68C.33 12.34 0 14.13 0 16v18c0 2.32.5 4.52 1.38 6.51l17.37-14.47L.91 10.68ZM50 34V16c0-1.87-.32-3.66-.9-5.32L31.24 26.04l17.37 14.47A15.9 15.9 0 0 0 50 34Z"
                clipRule="evenodd"
            />
            <path
                fill="#FFDC7C"
                fillRule="evenodd"
                d="M16 50h18a16 16 0 0 0 14.93-10.25l-21.4-17.52a3.8 3.8 0 0 0-5.05 0L1.07 39.75A16 16 0 0 0 16 50Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#ya)"
                fillRule="evenodd"
                d="M34 0H16A16 16 0 0 0 .67 11.4l21.8 19.33a3.8 3.8 0 0 0 5.05 0l21.79-19.4A16 16 0 0 0 34 0Z"
                clipRule="evenodd"
            />
            <defs>
                <linearGradient
                    id="ya"
                    x1={46.35}
                    x2={22.49}
                    y1={-4.69}
                    y2={25.45}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBE7D" />
                    <stop offset={1} stopColor="#FC4714" />
                </linearGradient>
            </defs>
        </svg>
    </SvgIcon>
)

export default SvgIconMailYandex
