import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconTikTok: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
        >
            <path
                fill="#EE1D52"
                d="M8.451 19.793c.156-1.294.687-2.019 1.687-2.761 1.43-1.006 3.218-.437 3.218-.437V13.22c.435-.011.87.014 1.3.077v4.343s-1.788-.569-3.219.438c-.999.742-1.53 1.467-1.686 2.76-.005.703.126 1.621.734 2.416a5.932 5.932 0 0 1-.46-.264c-1.337-.898-1.58-2.245-1.574-3.197ZM22.035 6.979c-.984-1.079-1.356-2.168-1.49-2.933h1.237s-.247 2.006 1.553 3.979l.025.026a7.133 7.133 0 0 1-1.325-1.072ZM28 10.037v4.256s-1.58-.062-2.75-.36c-1.632-.415-2.681-1.053-2.681-1.053s-.725-.456-.784-.487v8.789c0 .49-.134 1.711-.543 2.73-.533 1.334-1.356 2.21-1.508 2.388 0 0-1 1.183-2.767 1.98-1.592.719-2.99.7-3.407.719 0 0-2.417.095-4.59-1.318a9.125 9.125 0 0 1-1.312-1.053l.011.008c2.175 1.413 4.59 1.317 4.59 1.317.419-.018 1.817 0 3.408-.719 1.765-.797 2.767-1.98 2.767-1.98.15-.179.977-1.054 1.508-2.388.408-1.019.543-2.241.543-2.73v-8.788c.059.032.783.487.783.487s1.05.638 2.683 1.054c1.17.297 2.749.36 2.749.36V9.912c.54.121 1.001.154 1.3.124Z"
            />
            <path
                d="M26.7 9.913v3.334s-1.579-.062-2.748-.36c-1.633-.415-2.683-1.053-2.683-1.053s-.725-.455-.783-.488v8.79c0 .49-.134 1.712-.543 2.731-.533 1.334-1.356 2.21-1.508 2.388 0 0-1.001 1.183-2.767 1.98-1.591.719-2.99.7-3.408.719 0 0-2.415.096-4.59-1.317l-.01-.008a8.326 8.326 0 0 1-.648-.703c-.694-.846-1.12-1.847-1.227-2.133v-.003c-.172-.496-.533-1.688-.484-2.842.088-2.036.804-3.285.993-3.599a8.553 8.553 0 0 1 1.928-2.257 8.13 8.13 0 0 1 2.291-1.3 7.97 7.97 0 0 1 2.844-.572v3.375s-1.788-.567-3.218.437c-1 .742-1.53 1.467-1.687 2.76-.007.953.237 2.3 1.573 3.199.156.099.31.186.46.263.233.304.517.568.84.783 1.306.826 2.4.884 3.799.347.932-.359 1.635-1.167 1.96-2.063.205-.56.202-1.123.202-1.706V4.046h3.256c.134.765.506 1.854 1.49 2.933.396.407.84.767 1.325 1.072.144.149.876.881 1.816 1.331.487.233 1 .41 1.528.531Z"
            />
            <path
                fill="#69C9D0"
                d="M4.49 22.757v.002l.08.22c-.01-.026-.04-.104-.08-.222Z"
            />
            <path
                fill="#69C9D0"
                d="M10.513 13.792a8.136 8.136 0 0 0-2.291 1.3 8.554 8.554 0 0 0-1.927 2.262c-.19.312-.906 1.563-.993 3.599-.05 1.154.312 2.345.484 2.841v.004c.108.283.532 1.284 1.226 2.132.202.246.418.481.647.703a8.9 8.9 0 0 1-1.947-1.75c-.688-.838-1.112-1.828-1.223-2.12a.05.05 0 0 1 0-.007v-.003c-.172-.496-.535-1.688-.484-2.843.087-2.036.803-3.286.993-3.6a8.542 8.542 0 0 1 1.927-2.261 8.118 8.118 0 0 1 2.291-1.3 8.108 8.108 0 0 1 1.616-.456 8.372 8.372 0 0 1 2.527-.035v.962a7.971 7.971 0 0 0-2.846.572Z"
            />
            <path
                fill="#69C9D0"
                d="M20.544 4.046h-3.256v16.57c0 .582 0 1.144-.202 1.705-.328.896-1.028 1.704-1.96 2.063-1.4.539-2.493.48-3.798-.347a3.51 3.51 0 0 1-.844-.78c1.112.568 2.107.558 3.34.084.932-.359 1.632-1.168 1.96-2.064.205-.56.202-1.122.202-1.704V3h4.496s-.05.412.062 1.046ZM26.7 8.991v.922a7.29 7.29 0 0 1-1.524-.53c-.94-.45-1.673-1.183-1.817-1.332.167.105.34.2.517.285 1.143.547 2.27.71 2.824.655Z"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconTikTok
