import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconTumblr: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 260 260">
            <path d="M210.857 197.545a4.999 4.999 0 0 0-5.119.223c-11.62 7.638-23.4 11.511-35.016 11.511-6.242 0-11.605-1.394-16.416-4.275-3.27-1.936-6.308-5.321-7.397-8.263-1.057-2.797-1.045-10.327-1.029-20.748l.005-63.543h52.795a5 5 0 0 0 5-5V62.802a5 5 0 0 0-5-5h-52.795V5a5 5 0 0 0-5-5h-35.566a5.001 5.001 0 0 0-4.964 4.397c-1.486 12.229-4.258 22.383-8.247 30.196-3.89 7.7-9.153 14.401-15.651 19.925-5.206 4.44-14.118 8.736-26.49 12.769a5 5 0 0 0-3.45 4.754v35.41a5 5 0 0 0 5 5H80.47v82.666c0 12.181 1.292 21.347 3.952 28.026 2.71 6.785 7.521 13.174 14.303 18.993 6.671 5.716 14.79 10.187 24.158 13.298 9.082 2.962 16.315 4.567 28.511 4.567 10.31 0 20.137-1.069 29.213-3.179 8.921-2.082 19.017-5.761 30.008-10.934a5 5 0 0 0 2.871-4.524v-39.417a5.006 5.006 0 0 0-2.629-4.402z" />
        </svg>
    </SvgIcon>
)

export default SvgIconTumblr
