import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconYandexTips: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 36 36">
            <path
                fill="#FFE033"
                d="M0 11.952C0 2.11 2.11 0 11.952 0h12.096C33.891 0 36 2.11 36 11.952v12.096C36 33.891 33.89 36 24.048 36H11.952C2.11 36 0 33.89 0 24.048V11.952Z"
            />
            <path
                fillRule="evenodd"
                d="M27.779 33.173c-.873.194-1.875.335-3.029.429v2.394c9.012-.1 11.146-2.234 11.246-11.246h-2.394c-.094 1.154-.235 2.156-.428 3.029-.4 1.794-.994 2.901-1.744 3.651s-1.857 1.345-3.651 1.743ZM2.399 11.25c.093-1.154.234-2.156.428-3.028.399-1.795.993-2.902 1.743-3.652.75-.75 1.857-1.344 3.652-1.743.872-.194 1.874-.335 3.028-.428V.004C2.238.104.104 2.238.004 11.25h2.395ZM.004 24.75c.1 9.012 2.234 11.146 11.246 11.246v-2.394c-1.154-.094-2.156-.235-3.028-.428-1.795-.4-2.902-.994-3.652-1.744s-1.344-1.857-1.743-3.651c-.194-.873-.335-1.875-.428-3.029H.004Zm33.598-13.5c-.094-1.154-.235-2.156-.428-3.028-.4-1.795-.994-2.902-1.744-3.652s-1.857-1.344-3.651-1.743c-.873-.194-1.875-.335-3.029-.428V.004c9.012.1 11.146 2.234 11.246 11.246h-2.394Zm-6.319 13.367c-1.967 1.716-4.55 2.818-7.377 2.818-5.902 0-10.328-4.656-10.328-9.924 0-5.024 3.934-9.067 8.73-9.067 4.549 0 7.868 3.553 7.868 7.474 0 3.676-2.828 6.616-6.27 6.616-3.197 0-5.41-2.45-5.41-5.023 0-2.328 1.844-4.166 3.934-4.166 1.845 0 2.951 1.348 2.951 2.696 0 1.102-.738 1.715-1.475 1.715-.246 0-.37 0-.492-.123.246-.245.369-.735.246-1.102-.123-.735-.86-1.348-1.599-1.226-.614.123-1.106.613-1.229.858-.246.368-.369.858-.369 1.348 0 1.838 1.721 3.063 3.443 3.063 2.459 0 4.303-2.205 4.303-4.656 0-3.063-2.582-5.514-5.779-5.514-3.811 0-6.762 3.186-6.762 6.984 0 4.289 3.689 7.964 8.238 7.964 5.164 0 9.098-4.288 9.098-9.434 0-5.514-4.672-10.292-10.697-10.292-6.393 0-11.557 5.391-11.557 11.885.123 7.106 5.902 12.865 13.279 12.865 3.565 0 6.762-1.348 9.221-3.553l-1.967-2.206Z"
                clipRule="evenodd"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconYandexTips
