import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconMTSMusic: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            viewBox="0 0 52 52"
        >
            <g clipPath="url(#a)">
                <rect width={52} height={52} fill="#FF0032" rx={26} />
                <path
                    fill="#fff"
                    d="M19.862 41.025c3.633 0 6.577-2.955 6.577-6.6 0-3.645-2.944-6.6-6.577-6.6-3.632 0-6.577 2.955-6.577 6.6 0 3.645 2.945 6.6 6.577 6.6ZM26.438 8.465v11.44l12.277 6.16v-11.44l-12.278-6.16Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h52v52H0z" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
)

export default SvgIconMTSMusic
