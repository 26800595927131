import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconRUTUBE: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 16 15">
            <path
                fill="#fff"
                d="M8.41 9.006H2.595v-2.33H8.41c.34 0 .576.06.694.165.119.105.192.3.192.583v.836c0 .3-.073.493-.192.598-.118.105-.354.15-.694.15v-.002Zm.398-4.526H.131v9.964h2.465v-3.242H7.14l2.156 3.242h2.76L9.68 11.187c.876-.132 1.27-.404 1.594-.852.325-.448.488-1.165.488-2.121v-.747c0-.568-.06-1.016-.163-1.36a2.271 2.271 0 0 0-.532-.91 2.397 2.397 0 0 0-.915-.553c-.354-.105-.797-.165-1.343-.165v.001Z"
            />
            <path
                fill="#ED143B"
                d="M13.95 4.48a1.894 1.894 0 1 0 0-3.79 1.894 1.894 0 0 0 0 3.79Z"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconRUTUBE
