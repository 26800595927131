import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconPikabu: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 32 32">
            <defs>
                <linearGradient id="pkb0" x1="3.05" x2="13.03" y1="23.44" y2="31.87" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#DD571D"/>
                    <stop offset=".65" stopColor="#DF6132"/>
                </linearGradient>
                <radialGradient id="pkb1" cx="0" cy="0" r="1"
                                gradientTransform="matrix(11.6095 .75457 -.56841 8.74533 13.24 18.89)"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BD350E"/>
                    <stop offset=".44" stopColor="#BF3710" stopOpacity=".51"/>
                    <stop offset=".62" stopColor="#C43E15" stopOpacity=".3"/>
                    <stop offset=".76" stopColor="#CE4B20" stopOpacity=".15"/>
                    <stop offset=".87" stopColor="#DB5C2E" stopOpacity=".03"/>
                    <stop offset=".89" stopColor="#DF6132" stopOpacity=".01"/>
                </radialGradient>
                <radialGradient id="pkb2" cx="0" cy="0" r="1" gradientTransform="matrix(7.21222 0 0 10.9971 4.56 17.34)"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E05E21"/>
                    <stop offset=".56" stopColor="#DF602A" stopOpacity=".37"/>
                    <stop offset=".89" stopColor="#DF6132" stopOpacity=".01"/>
                </radialGradient>
                <radialGradient id="pkb3" cx="0" cy="0" r="1"
                                gradientTransform="matrix(6.56903 -.50925 .72514 9.35388 9.55 19.07)"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E05E21"/>
                    <stop offset=".56" stopColor="#DF602A" stopOpacity=".37"/>
                    <stop offset=".89" stopColor="#DF6132" stopOpacity=".01"/>
                </radialGradient>
                <radialGradient id="pkb4" cx="0" cy="0" r="1"
                                gradientTransform="matrix(3.6871 1.32408 -1.5528 4.32403 19.22 24.54)"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C13B12"/>
                    <stop offset=".72" stopColor="#DD5D28" stopOpacity=".41"/>
                    <stop offset=".99" stopColor="#DF602A" stopOpacity=".01"/>
                    <stop offset="1" stopColor="#DF6132" stopOpacity=".01"/>
                </radialGradient>
                <radialGradient id="pkb5" cx="0" cy="0" r="1"
                                gradientTransform="rotate(-155.06 12.96 2.95) scale(28.6853 24.6493)"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE491"/>
                    <stop offset=".13" stopColor="#FFE491"/>
                    <stop offset=".3" stopColor="#F9C16E"/>
                    <stop offset=".53" stopColor="#F19645"/>
                    <stop offset="1" stopColor="#EE8635"/>
                </radialGradient>
                <radialGradient id="pkb6" cx="0" cy="0" r="1"
                                gradientTransform="matrix(2.47103 -3.42617 11.3089 8.15624 18.457 4.628)"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E74C29"/>
                    <stop offset=".26" stopColor="#DE4926"/>
                    <stop offset=".67" stopColor="#C7421C"/>
                    <stop offset="1" stopColor="#B03B13"/>
                </radialGradient>
            </defs>
            <path
                fill="url(#pkb0)"
                d="M24.01 23.66c.6-.51 1.2-1.04 1.77-1.58-3.25-.5-22.2-11.15-22.22-11.14-.42 2.23-.8 4.53-.67 6.8.1 2.02.37 3.56 1.41 5.07a9.7 9.7 0 0 0 1.85 1.88 14.69 14.69 0 0 0 8.2 3.45 10.36 10.36 0 0 0 6.4-1.88c.94-.64 2.23-1.73 3.26-2.6z"/>
            <path
                fill="url(#pkb1)"
                d="M24.01 23.66c.6-.51 1.2-1.04 1.77-1.58-3.25-.5-22.2-11.15-22.22-11.14-.42 2.23-.8 4.53-.67 6.8.1 2.02.37 3.56 1.41 5.07a9.7 9.7 0 0 0 1.85 1.88 14.69 14.69 0 0 0 8.2 3.45 10.36 10.36 0 0 0 6.4-1.88c.94-.64 2.23-1.73 3.26-2.6z"/>
            <path
                fill="url(#pkb2)"
                d="M6.98 14.95s-1.79 4.74-2.01 6.98c-.22 2.24 2.01 2.35 2.57 1.95.56-.4 2.08-1.58 3.95-5.44 1.86-3.86-3.02-6.91-4.51-3.5z"
                opacity=".85"/>
            <path
                fill="url(#pkb3)"
                d="M12.6 17.3s-2.53 5.1-3.5 7.31c-.93 2.06 2.12 1.91 2.65 1.47.52-.44 2.71-1.58 4.24-5.55 1.53-3.97-1.23-6.87-3.39-3.23z"
                opacity=".85"/>
            <path
                fill="url(#pkb4)"
                d="M20.45 21.55s-4.32 5.12-7 7.3c-2.27 1.82-2.83-.83-2.74-1.67.14-.84.36-3.62 3.66-7.95 3.3-4.33 8.58-2.42 6.08 2.32z"
                opacity=".85"/>
            <path
                fill="url(#pkb5)"
                d="M16.06 3.44s-4.95-1.5-8.56-.18C3.9 4.58 2.25 6.31 3.26 10.5c1 4.19 10.9 10.4 15.08 11.61 4.17 1.22 8.04 1.4 9.86-.48 1.83-1.87 2.87-6.83-2.6-12.45-5.48-5.62-9.54-5.74-9.54-5.74z"/>
            <path
                fill="url(#pkb6)"
                d="M13.87 5.76s-.3-1.25.93-2.17c1.23-.92 3.95-.99 6.81.74 2.87 1.72 3.8 3.34 3.99 4.85.18 1.5-.38 2.68-2.87 2.72-2.53.03-7.82-2.28-8.86-6.14z"/>
        </svg>
    </SvgIcon>
)

export default SvgIconPikabu
