import {FC} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconMTSMusic: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 39 39">
            <title>{"icon/services/apple music"}</title>
            <g fill="none" fillRule="nonzero">
                <circle cx={19.5} cy={19.5} r={19.5} fill="#F8F8F8" />
                <g fill="#3C3C3C">
                    <path d="M19.455 13.734c.408 0 1.73-.259 2.755-1.75.877-1.293.779-2.734.779-2.734s-1.52-.11-2.854 1.54c-.964 1.207-.89 2.931-.89 2.931s.074.013.21.013Z" />
                    <path d="M27.078 23.205c.074-.21.124-.332.124-.332h-.013s-.012 0-.024-.013a2.593 2.593 0 0 1-.47-.234 4.174 4.174 0 0 1-1.507-1.515c-.284-.492-.482-1.108-.531-1.872a3.863 3.863 0 0 1 .086-1.084v-.012c.025-.11.05-.21.087-.32.395-1.281 1.322-2.119 1.692-2.402l.148-.11s-1.186-1.836-3.422-1.836c-1.927 0-2.644.862-3.67.862-1.26 0-1.457-.8-3.323-.8-.963 0-2.31.37-3.31 1.662-.792 1.01-1.088 2.205-1.212 3.08a8.437 8.437 0 0 0-.024 1.946c0 .049.012.098.012.16.074.714.26 1.478.519 2.241 0 .013.012.037.025.05.024.086.061.184.098.27.013.037.037.087.05.124.012.037.025.086.05.123.246.64.53 1.256.864 1.835.964 1.7 2.212 2.98 3.385 2.98 1.335 0 1.755-.861 3.09-.85 1.692.013 2.161 1.085 3.582.801.951-.184 2.088-1.527 2.817-2.808.025-.049.05-.086.074-.123.346-.739.643-1.429.803-1.823Z" />
                </g>
            </g>
        </svg>
    </SvgIcon>
)

export default SvgIconMTSMusic
