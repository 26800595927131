import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconYandexMusic: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            viewBox="0 0 24 24"
        >
            <path
                fill="#FFBC0D"
                d="m23.773 9.148-.094-.465-3.957-.692 2.3-3.111-.268-.293L18.37 6.21l.428-4.308-.348-.2-2.059 3.485L14.08 0h-.401l.548 5.013L8.41.359l-.495.146 4.48 5.625L3.53 3.178l-.401.452 7.915 4.507L.12 9.042 0 9.72l11.351 1.236-9.466 7.832.401.545 11.272-6.13L11.325 24h.682l4.318-10.159 2.634 7.952.468-.36-1.083-8.083 4.105 4.653.267-.425-3.142-5.77 4.386 1.621.04-.492-3.93-2.898 3.703-.891Z"
            />
        </svg>
    </SvgIcon>
)

export default SvgIconYandexMusic
