import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconSFEROOM: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg
            viewBox="0 0 120 120"
        >
            <path
                d="m29.6 53.46 29.52 29.52L29.6 112.5.08 82.98 29.6 53.46ZM90.48 9.82 120 39.34 90.48 68.86 60.96 39.34 90.48 9.82Z"
                className="logo-block-1 logo-block--part"
                style={{
                    fill: "#ffbb61",
                    transition: "all .8s ease 0s",
                }}
            />
            <path
                d="M0 39.34v41.89L28.53 52.5l.19-42.17L0 39.34ZM91.28 70.25v41.9l28.53-28.73.19-42.17-28.72 29Z"
                className="logo-block-2 logo-block--part"
                style={{
                    fill: "#d19752",
                    transition: "all .8s ease 0s",
                }}
            />
            <path
                d="M90 70.09v41.52L30 52.35V10l60 60.09Z"
                className="logo-block-center logo-block--part"
                style={{
                    fill: "#a67c52",
                    transition: "all .8s ease 0s",
                }}
            />
        </svg>
    </SvgIcon>
)

export default SvgIconSFEROOM
